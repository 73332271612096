import React, { useState } from 'react'
import Cart from './Cart'

const Shop = (props) => {
    let currentCard = props.state.cardNumber

    const renderSwitch = () => {
        let currentCard = props.state.cardNumber
        switch (currentCard) {
            case 5:
                return <p className='pack-type'>Pack of Six Blank Greating Cards ${props.state.blankCost}</p>
            case 6: 
                return <p className='pack-type'>Pack of Six Greating Cards with Original Poetry ${props.state.poemCost}</p> 
            default:
                return <p className='pack-type'>Pack of Eight Gift Tags ${props.state.tagCost}</p>
            case 7: 
                return <p className='pack-type'>Calendar ${props.state.calendarCost}</p>
    }

}
    return (
        <>
            <div className='shop-div'>
                {renderSwitch(currentCard)}
                <div className='add-div'>
                    <input id='qty' className='qty' name='qty' type='text' placeholder='Qty' onChange={props.handleChange} onBlur={props.handleBlur}></input>
                    <button className='add-cart' onClick={props.handleClick}>Add To Cart</button>
                </div>
            </div>
        </>
    )
}

export default Shop