import React from 'react'
import Nav from './Nav'

const Header = ( props ) => (
    <>
      <div className='header'>
        <span className='headerImageSpan' title='Home'>
        <img className='headerImage' src='/images/Logo.png' onClick={props.home} ></img>
        </span>
        <Nav cards={props.cards} home={props.home}/>
      </div>
    </>
  )


export default Header