import React from 'react'
import Home from './Home'
import Header from './Header'
import Contact from './Contact'
import CardViewer from './CardViewer'


//start of App component
class App extends React.Component  {
  constructor(props) {
    super(props)
    this.state ={
      view: 'home',
      tagQty: 0,
      blankQty: 0,
      poemQty: 0,
      calendarQty: 0,
      tagCost: 10,
      blankCost: 20,
      poemCost: 10,
      calendarCost: 30,
      postCost: 3.5,
      cart: {
        tag: 0,
        blank: 0,
        poem: 0,
        calendar: 0,
      },
      total: 0,
      cardNumber: 4,
      totalCards: 7,
      post: false,
    }
  }
  //functions to set view state that cn be passed to props 
  showCards = () => {
    this.setState({view: 'cards'})
  }

  showHome = () => {
    this.setState({view: 'home'})
  }

  handleClickNext = event => {
    event.preventDefault()
    //change card_number state
    if (this.state.cardNumber < this.state.totalCards) {
      this.setState({cardNumber: this.state.cardNumber + 1})
    } else {
      this.setState({cardNumber: 4})
    }
  }

  handleClickBack = event => {
    event.preventDefault()
    //change cardNumber state
    if (this.state.cardNumber > 1) {
      this.setState({cardNumber: this.state.cardNumber -1})
    } else {
      this.setState({cardNumber: this.state.totalCards})
    } 
  }

  handleChange = (event) => {
    let currentCard = this.state.cardNumber
    switch (currentCard) {
        case 4:
            this.setState({tagQty: event.target.value})
            break
        case 5:
            this.setState({blankQty: event.target.value})
            break
        case 6:
            this.setState({poemQty: event.target.value})
            break
        case 7:
            this.setState({calendarQty: event.target.value})
            break
    } 
}

  handleBlur = event => {
    event.preventDefault()
    this.setState({
      cart:{
        tag: Number(this.state.cart.tag) + Number(this.state.tagQty),
        blank: Number(this.state.cart.blank) + Number(this.state.blankQty),
        poem: Number(this.state.cart.poem) + Number(this.state.poemQty),
        calendar: Number(this.state.cart.calendar) + Number(this.state.calendarQty),
      }
    })
    this.setState({tagQty: 0})
    this.setState({blankQty: 0})
    this.setState({poemQty: 0})
    this.setState({calendarQty: 0})
    document.getElementById('qty').value = 0
  }

  addPostage = () => {
    this.setState({total: this.state.total + 3.5})
  }

  removePostage = () => {
    this.setState({total: this.state.total - 3.5})
  }

  setPost = (value) => {
    this.setState({post: value})
  }

  calcTotal = () =>{
    let total = Number(this.state.cart.tag) * this.state.tagCost + Number(this.state.cart.blank) * this.state.blankCost + Number(this.state.cart.poem) * this.state.poemCost + Number(this.state.cart.calendar) * this.state.calendarCost
    return total
  }

  handleClick = event => {
    event.preventDefault()
    
    if (this.state.post) {
      this.setState({total: this.calcTotal() + postCost})
    } else {
        this.setState({total: this.calcTotal()})  
      }
  } 
    
  // render app
  render(){
    return (
      <>
        {/* pass view state functions into Header components props */}
        <Header cards={this.showCards} home={this.showHome}/>
        {/* turnery operator to select view, change to if statement if more views added */}
        {
        this.state.view === 'home' ? 
        <Home /> : <CardViewer
                      next={this.handleClickNext} back={this.handleClickBack} handleChange={this.handleChange} handleBlur={this.handleBlur} handleClick={this.handleClick} addPostage={this.addPostage} removePostage={this.removePostage} setPost={this.setPost} state={this.state} 
                  />
        }
        <Contact state={this.state}/>
      </>
    )
  }
}
//export App component
export default App
